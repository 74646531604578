import React from 'react';
import { Layout } from 'components/common';
import { Container } from 'components/common/Container';
import { Header } from 'components/theme';
import Logo from 'assets/logo/logo-condensed.inline.svg';
import styled from 'styled-components';

export default () => (
  <Layout>
    <Header product="advertising" />
    <Wrapper>
      <Logo height="300px" width="300px" />
      <h1>404 - Page Not Found</h1>
    </Wrapper>
  </Layout>
);

const Wrapper = styled(Container)`
  text-align: center;
  min-height: calc(100vh - 14.6rem);
  padding-top: 2rem;
`;
